<template>
  <div style="position: relative; height: 640px">
    <v-jsoneditor
      style="position: absolute; height: auto; top: 0; bottom: 0"
      v-model="internalJson"
      :options="jsonOptions"
      :plus="plus"
      :height="editorHeight"
      @input="$emit('updated', internalJson)"
      @error="onError"
    ></v-jsoneditor>
  </div>
</template>

<script>
import VJsoneditor from "v-jsoneditor";

export default {
  name: "AdvancedJsonEditor",
  components: {
    VJsoneditor,
  },
  props: {
    json: Object,
    options: Object,
    height: { type: Number, default: 400 },
    plus: { type: Boolean, default: false },
  },
  data() {
    return {
      internalJson: {},
      jsonOptions: {
        mode: "tree",
        indentation: 2,
        limitDragging: true,
        modes: ["form", "tree", "preview", "code"],
        ...this.options,
      },
    };
  },
  mounted() {
    this.internalJson = this.json;
  },
  computed: {
    editorHeight() {
      return this.height + "px";
    },
  },
  methods: {
    onError(err) {
      //TODO: show error
    },
  },
  watch: {
    internalJson: {
      deep: true,
      handler(val) {
        // console.log(val);
      },
    },
  },
};
</script>

<style lang="scss">
.jsoneditor-modal .pico-modal-header {
  background-color: rgb(230, 58, 43);
}

.jsoneditor-modal
.jsoneditor-button-group.jsoneditor-button-group-value-asc
input.jsoneditor-button-asc,
.jsoneditor-modal
.jsoneditor-button-group.jsoneditor-button-group-value-desc
input.jsoneditor-button-desc {
  border-color: rgb(230, 58, 43);
  background-color: rgb(230, 58, 43);
}

.jsoneditor-popover,
.jsoneditor-schema-error,
div.jsoneditor-field,
div.jsoneditor-value,
div.jsoneditor td,
div.jsoneditor textarea,
div.jsoneditor th,
pre.jsoneditor-preview {
  font-size: 14px;
}

.jsondiffpatch-delta {
  display: block !important;
  word-break: break-word;
}
</style>

<style scoped lang="scss">
::v-deep {
  .jsoneditor-menu {
    background-color: rgb(230, 58, 43);
    border-color: rgb(230, 58, 43);
  }

  .jsoneditor-contextmenu {
    .jsoneditor-menu {
      background-color: white;
    }
  }

  .jsoneditor {
    border-color: rgb(230, 58, 43);
  }

  .jsoneditor-tree {
    zoom: 0.95;
  }
}
</style>
