<template>
  <div class="journey-editor">
    <el-row :gutter="20">
      <el-col :span="currentNode ? 16 : 24">
        <div style="text-align: right; margin-bottom: 10px">
          <el-dropdown @command="addNode">
            <el-button type="primary" size="small">
              <i class="el-icon-plus"></i> Add Step
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Milestone">Milestone</el-dropdown-item>
              <el-dropdown-item command="Notification">Notification</el-dropdown-item>
              <el-dropdown-item command="Alert">Alert</el-dropdown-item>
              <el-dropdown-item command="Appointment Booking">Appointment Booking</el-dropdown-item>
              <el-dropdown-item command="Vital Monitoring">Vital Monitoring</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <VueDiagramEditor
          ref="diagram"
          :nodeDeletable="nodeDeletable"
          :node-color="nodeColor"
          @select-node="onSelectNode"
          @updated-node="onUpdateNode"
          @deleted-node="onDeleteNode"
          @created-link="onCreateLink"
          @deleted-link="onDeleteLink"
          style="border: 1px solid grey"
        >
          <template slot="node" slot-scope="{ node }">
            <div
              v-if="nodes[node.id].type === 'Start'"
              style="text-align: center; height: 100%; font-size: 20px"
            >
              <i class="el-icon-caret-right"></i>
            </div>
            <div
              v-else-if="nodes[node.id].type === 'End'"
              style="text-align: center; height: 100%; font-size: 20px"
            >
              <i class="el-icon-error"></i>
            </div>
            <div
              v-else-if="nodes[node.id].type === 'Alert'"
              style="text-align: center; height: 100%"
            >
              <el-tag
                style="margin-top: 10px"
                size="small"
                type="danger"
                v-if="nodes[node.id].alert_priority === 'High'"
              >
                <i class="el-icon-warning"></i>
                {{
                  nodes[node.id].alert_type ? nodes[node.id].alert_type.substring(0, 15) : "Alert"
                }}
              </el-tag>
              <el-tag
                style="margin-top: 10px"
                size="small"
                type="warning"
                v-if="nodes[node.id].alert_priority === 'Normal'"
              >
                <i class="el-icon-warning"></i>
                {{
                  nodes[node.id].alert_type ? nodes[node.id].alert_type.substring(0, 15) : "Alert"
                }}
              </el-tag>
              <el-tag
                style="margin-top: 10px"
                size="small"
                type="info"
                v-if="nodes[node.id].alert_priority === 'Low'"
              >
                <i class="el-icon-warning"></i>
                {{
                  nodes[node.id].alert_type ? nodes[node.id].alert_type.substring(0, 15) : "Alert"
                }}
              </el-tag>
              <div></div>
            </div>
            <div
              v-else-if="nodes[node.id].type === 'Notification'"
              style="text-align: center; height: 100%"
            >
              <el-tag style="margin-top: 10px" size="small" type="info">
                <i class="el-icon-message-solid"></i> Notification
              </el-tag>
            </div>
            <div
              v-else-if="nodes[node.id].type === 'Milestone'"
              style="text-align: center; height: 100%"
            >
              <el-tag style="margin-top: 10px" size="small" type="success">
                <i class="el-icon-tickets"></i>
                {{ nodes[node.id].title.substring(0, 15) }}
              </el-tag>
            </div>
            <div
              v-else-if="nodes[node.id].type === 'Vital Monitoring'"
              style="text-align: center; height: 100%"
            >
              <el-tag style="margin-top: 10px" size="small" type="warning">
                <i class="el-icon-edit-outline"></i>
                {{
                  nodes[node.id].vital_type
                    ? vitalLabel(nodes[node.id].vital_type).substring(0, 15)
                    : "Vital Monitoring"
                }}
              </el-tag>
            </div>
            <div
              v-else-if="nodes[node.id].type === 'Appointment Booking'"
              style="text-align: center; height: 100%"
            >
              <el-tag style="margin-top: 10px" size="small" type="success">
                <i class="el-icon-date"></i>
                {{ nodes[node.id].title.substring(0, 15) }}
              </el-tag>
            </div>
            <div v-else style="text-align: center; height: 100%">
              {{ nodes[node.id].title }}
            </div>
          </template>
        </VueDiagramEditor>
      </el-col>
      <el-col :span="8" v-if="currentNode">
        <el-card style="min-height: 550px">
          <div slot="header" class="clearfix">
            <h3>{{ nodes[currentNodeId].title }}</h3>
          </div>

          <div v-if="currentNode.type === 'Start'" style="height: 400px; overflow: auto">
            <label class="journey-label">How to start this journey</label>
            <br />
            <el-radio v-model="currentNode.start_rule" label="add_patient">
              When adding a patient to the journey
            </el-radio>
            <br />
            <el-radio v-model="currentNode.start_rule" label="with_rules">
              With Transition Rules
            </el-radio>
            <br /><br />
            <div v-if="currentNode.start_rule === 'with_rules'">
              <div v-if="transitions.length > 0">
                <el-row v-for="(itm, idx) in transitions[0].rules" :key="'start' + idx">
                  <el-col :span="2">
                    <strong>#{{ idx + 1 }}</strong>
                  </el-col>
                  <el-col :span="20">
                    <el-row>
                      <el-col :span="24">
                        <el-select
                          v-model="itm.variable"
                          filterable
                          allow-create
                          placeholder="Select"
                          style="width: 100%"
                        >
                          <el-option-group label="PROM/PREM">
                            <el-option
                              v-for="frm in forms"
                              :key="frm.id"
                              :label="frm.name"
                              :value="frm.id"
                            >
                            </el-option>
                          </el-option-group>
                          <el-option-group label="Patient Data">
                            <el-option
                              v-for="opt in patientFields"
                              :key="opt.value"
                              :label="opt.label"
                              :value="opt.value"
                            >
                            </el-option>
                          </el-option-group>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row type="flex" justify="center">
                      <el-col :span="12">
                        <el-select v-model="itm.operation" placeholder="Select" style="width: 100%">
                          <el-option
                            v-for="opt in operationOpt"
                            :key="opt.value"
                            :label="opt.label"
                            :value="opt.value"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-select v-model="itm.type" placeholder="Select" style="width: 100%">
                          <el-option
                            v-for="opt in valueType"
                            :key="opt.value"
                            :label="opt.label"
                            :value="opt.value"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="12">
                        <el-option-group label="PROM/PREM"> </el-option-group>
                        <el-select
                          v-model="itm.value"
                          filterable
                          allow-create
                          placeholder="Select"
                          style="width: 100%"
                          v-if="itm.type === 'variable'"
                        >
                          <el-option-group label="PROM/PREM">
                            <el-option
                              v-for="frm in forms"
                              :key="frm.id"
                              :label="frm.name"
                              :value="frm.id"
                            >
                            </el-option>
                          </el-option-group>
                          <el-option-group label="Patient Data">
                            <el-option
                              v-for="opt in patientFields"
                              :key="opt.value"
                              :label="opt.label"
                              :value="opt.value"
                            >
                            </el-option>
                          </el-option-group>
                        </el-select>
                        <div style="text-align: center" v-else-if="itm.type === 'boolean'">
                          <el-switch v-model="itm.value"></el-switch>
                          <label v-if="itm.value"> TRUE </label>
                          <label v-else> FALSE </label>
                        </div>
                        <el-input placeholder="Value" v-model="itm.value" v-else></el-input>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="2">
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      size="small"
                      @click="deleteRule(0, idx)"
                    ></el-button>
                  </el-col>
                </el-row>
              </div>
              <el-button type="text" @click="addRules(0)"> + add rule </el-button>
            </div>
          </div>
          <div v-else>
            <label class="journey-label">Title</label>
            <el-input placeholder="Title" v-model="currentNode.title"></el-input>
            <br /><br />

            <el-tabs v-model="activeTab">
              <el-tab-pane label="Actions" name="actions" style="height: 300px; overflow: auto">
                <div v-if="false">
                  <label class="journey-label">Action</label>
                  <el-select v-model="currentNode.action" placeholder="Select" style="width: 100%">
                    <el-option
                      v-for="item in actionOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <br /><br />
                </div>

                <div v-if="currentNode.action === 'fill_form' && currentNode.type !== 'Milestone'">
                  <label class="journey-label">Form</label>
                  <el-select v-model="currentNode.form_id" placeholder="Select" style="width: 100%">
                    <el-option
                      v-for="item in forms"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                  <br /><br />
                </div>
                <div v-else-if="currentNode.action === 'send_message'">
                  <label class="journey-label">Message type</label>
                  <el-select
                    v-model="currentNode.message_type"
                    placeholder="Select"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in messageOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <br /><br />
                </div>

                <div v-if="currentNode.message_type === 'content_node'">
                  <label class="journey-label">Content Node</label>
                  <el-select
                    v-model="currentNode.content_node"
                    placeholder="Select"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, idx) in contentNodes"
                      :key="'nodes' + idx"
                      :label="item.id"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                  <br /><br />
                </div>
                <div v-else-if="currentNode.message_type === 'whatsapp_template'">
                  <label class="journey-label">WhatsApp Template</label>
                  <el-select
                    v-model="currentNode.whatsapp_template"
                    placeholder="Select"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="temp in whatsappTemplates"
                      :key="'watmp' + temp.id"
                      :value="temp.id"
                      :label="temp.name + '(' + temp.language + ')'"
                    ></el-option>
                  </el-select>
                  <br /><br />
                </div>
                <div v-else-if="currentNode.message_type === 'text_message'">
                  <label class="journey-label">Text Message</label>
                  <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="Please input"
                    v-model="currentNode.text_message"
                  >
                  </el-input>
                  <br /><br />
                </div>

                <div v-if="currentNode.type === 'Alert'">
                  <div>
                    <label class="journey-label">Alert Priority</label>
                    <el-select
                      v-model="currentNode.alert_priority"
                      placeholder="Select"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in priorityOpt"
                        :key="'prio-' + item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                    <br /><br />
                  </div>
                  <div>
                    <label class="journey-label">Alert Type</label>
                    <el-input placeholder="Title" v-model="currentNode.alert_type"></el-input>
                    <br /><br />
                  </div>
                </div>
                <div v-else-if="currentNode.type === 'Milestone'">
                  <label class="journey-label">Set Milestone</label>
                  <el-select
                    v-model="currentNode.milestone"
                    placeholder="Select"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in this.journey.milestones"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                  <br /><br />
                  <label class="journey-label" style="margin-bottom: 10px; display: block">
                    Select PROM/PREM Form
                  </label>
                  <el-row
                    v-for="(item, index) in formList"
                    :key="'form' + item.id"
                    style="margin-bottom: 10px"
                  >
                    <el-col :span="20">
                      <el-select v-model="item.id" placeholder="Select" style="width: 100%">
                        <el-option
                          v-for="item in forms"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        >
                        </el-option>
                        <el-button slot="append" icon="el-icon-search"></el-button>
                      </el-select>
                    </el-col>
                    <el-col :span="4" style="text-align: center">
                      <el-button
                        type="danger"
                        icon="el-icon-delete"
                        circle
                        size="small"
                        @click="deleteForm(index)"
                      ></el-button>
                    </el-col>
                  </el-row>
                  <el-button type="text" @click="addForm()"> + add form </el-button>
                </div>
                <div v-else-if="currentNode.type === 'Notification'">
                  <el-checkbox v-model="currentNode.send_form">
                    <span class="journey-label"> Send PROM/PREM form in this step </span>
                  </el-checkbox>
                  <br /><br />
                  <div v-if="currentNode.send_form">
                    <label class="journey-label">Set Milestone</label>
                    <el-select
                      v-model="currentNode.milestone"
                      placeholder="Select"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in this.journey.milestones"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <br /><br />
                    <label class="journey-label" style="margin-bottom: 10px; display: block">
                      Form
                    </label>
                    <el-row
                      v-for="(item, index) in formList"
                      :key="'form' + item.id"
                      style="margin-bottom: 10px"
                    >
                      <el-col :span="20">
                        <el-select v-model="item.id" placeholder="Select" style="width: 100%">
                          <el-option
                            v-for="item in forms"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                          <el-button slot="append" icon="el-icon-search"></el-button>
                        </el-select>
                      </el-col>
                      <el-col :span="4" style="text-align: center">
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          circle
                          size="small"
                          @click="deleteForm(index)"
                        ></el-button>
                      </el-col>
                    </el-row>
                    <el-button type="text" @click="addForm()"> + add form </el-button>
                  </div>
                </div>
                <div v-else-if="currentNode.type === 'Appointment Booking'">
                  <label class="journey-label">Set Third-party Appointment App </label>
                  <el-select
                    v-model="currentNode.appointment_app"
                    placeholder="Select"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="temp in appointmentApp"
                      :key="'app' + temp.value"
                      :value="temp.value"
                      :label="temp.label"
                    ></el-option>
                  </el-select>
                  <br /><br />
                  <div v-if="currentNode.appointment_app === 'calendly'">
                    <label class="journey-label">Calendly Link</label>
                    <el-input placeholder="Link" v-model="currentNode.calendly_link"></el-input>
                  </div>
                  <div v-if="currentNode.appointment_app === 'google'">
                    <label class="journey-label">Google Calendar Link</label>
                    <el-input
                      placeholder="Link"
                      v-model="currentNode.google_calendar_link"
                    ></el-input>
                  </div>
                </div>
                <div v-else-if="currentNode.type === 'Vital Monitoring'">
                  <label class="journey-label">Select Vital Reading</label>
                  <el-select
                    v-model="currentNode.vital_type"
                    placeholder="Select"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="temp in vitalType"
                      :key="'vital' + temp.value"
                      :value="temp.value"
                      :label="temp.label"
                    ></el-option>
                  </el-select>
                  <br /><br />
                </div>
              </el-tab-pane>
              <el-tab-pane
                label="Calculations"
                name="calculations"
                style="height: 300px; overflow: auto"
              >
                <el-row v-for="(itm, index) in calculations" :key="'cal' + index">
                  <el-col :span="2">
                    <strong>#{{ index + 1 }}</strong>
                  </el-col>
                  <el-col :span="20">
                    <el-row>
                      <el-col :span="24">
                        <el-select
                          v-model="itm.variable"
                          filterable
                          allow-create
                          placeholder="Select"
                          style="width: 100%"
                        >
                          <el-option-group label="PROM/PREM">
                            <el-option
                              v-for="frm in forms"
                              :key="frm.id"
                              :label="frm.name"
                              :value="frm.id"
                            >
                            </el-option>
                          </el-option-group>
                          <el-option-group label="Patient Data">
                            <el-option
                              v-for="opt in patientFields"
                              :key="opt.value"
                              :label="opt.label"
                              :value="opt.value"
                            >
                            </el-option>
                          </el-option-group>
                        </el-select>
                      </el-col>
                      <el-col :span="12">
                        <el-select v-model="itm.type" placeholder="Select" style="width: 100%">
                          <el-option
                            v-for="opt in valueType"
                            :key="opt.value"
                            :label="opt.label"
                            :value="opt.value"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="12">
                        <el-select
                          v-model="itm.value"
                          filterable
                          allow-create
                          placeholder="Select"
                          style="width: 100%"
                          v-if="itm.type === 'variable'"
                        >
                          <el-option-group label="PROM/PREM">
                            <el-option
                              v-for="frm in forms"
                              :key="frm.id"
                              :label="frm.name"
                              :value="frm.id"
                            >
                            </el-option>
                          </el-option-group>
                          <el-option-group label="Patient Data">
                            <el-option
                              v-for="opt in patientFields"
                              :key="opt.value"
                              :label="opt.label"
                              :value="opt.value"
                            >
                            </el-option>
                          </el-option-group>
                        </el-select>
                        <div style="text-align: center" v-else-if="itm.type === 'boolean'">
                          <el-switch v-model="itm.value"></el-switch>
                          <label v-if="itm.value"> TRUE </label>
                          <label v-else> FALSE </label>
                        </div>
                        <el-input placeholder="Value" v-model="itm.value" v-else></el-input>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="2">
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      size="small"
                      @click="deleteCalculations(index)"
                    ></el-button>
                  </el-col>
                </el-row>
                <div>
                  <el-button type="text" @click="addCalculations"> + add calculations </el-button>
                </div>
              </el-tab-pane>
              <el-tab-pane
                label="Transitions"
                name="transition"
                style="height: 300px; overflow: auto"
              >
                <div>
                  <label class="journey-label">Transitions</label>
                  <div
                    v-for="(item, index) in transitions"
                    :key="'tran' + index"
                    style="padding: 10px; border: solid 1px lightblue; margin-bottom: 10px"
                  >
                    <div>
                      <el-button
                        type="danger"
                        icon="el-icon-delete"
                        circle
                        size="small"
                        @click="deleteTransition(index)"
                        style="float: right"
                      ></el-button>
                    </div>
                    <el-checkbox v-model="item.condition_flag">
                      <span class="journey-label">
                        {{ item.condition_flag ? "If Condition" : "All Conditions" }}
                      </span>
                    </el-checkbox>
                    <br /><br />
                    <el-input placeholder="Title" v-model="item.title"></el-input>
                    <br /><br />
                    <div v-if="item.condition_flag">
                      <el-row v-for="(itm, idx) in item.rules" :key="'rule' + index + idx">
                        <el-col :span="2">
                          <strong>#{{ idx + 1 }}</strong>
                        </el-col>
                        <el-col :span="20">
                          <el-row>
                            <el-col :span="24">
                              <el-select
                                v-model="itm.variable"
                                filterable
                                allow-create
                                placeholder="Select"
                                style="width: 100%"
                              >
                                <el-option-group label="PROM/PREM">
                                  <el-option
                                    v-for="frm in forms"
                                    :key="frm.id"
                                    :label="frm.name"
                                    :value="frm.id"
                                  >
                                  </el-option>
                                </el-option-group>
                                <el-option-group label="Patient Data">
                                  <el-option
                                    v-for="opt in patientFields"
                                    :key="opt.value"
                                    :label="opt.label"
                                    :value="opt.value"
                                  >
                                  </el-option>
                                </el-option-group>
                              </el-select>
                            </el-col>
                          </el-row>
                          <el-row type="flex" justify="center">
                            <el-col :span="12">
                              <el-select
                                v-model="itm.operation"
                                placeholder="Select"
                                style="width: 100%"
                              >
                                <el-option
                                  v-for="opt in operationOpt"
                                  :key="opt.value"
                                  :label="opt.label"
                                  :value="opt.value"
                                >
                                </el-option>
                              </el-select>
                            </el-col>
                            <el-col :span="12">
                              <el-select
                                v-model="itm.type"
                                placeholder="Select"
                                style="width: 100%"
                              >
                                <el-option
                                  v-for="opt in valueType"
                                  :key="opt.value"
                                  :label="opt.label"
                                  :value="opt.value"
                                >
                                </el-option>
                              </el-select>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="24">
                              <el-select
                                v-model="itm.value"
                                filterable
                                allow-create
                                placeholder="Select"
                                style="width: 100%"
                                v-if="itm.type === 'variable'"
                              >
                                <el-option-group label="PROM/PREM">
                                  <el-option
                                    v-for="frm in forms"
                                    :key="frm.id"
                                    :label="frm.name"
                                    :value="frm.id"
                                  >
                                  </el-option>
                                </el-option-group>
                                <el-option-group label="Patient Data">
                                  <el-option
                                    v-for="opt in patientFields"
                                    :key="opt.value"
                                    :label="opt.label"
                                    :value="opt.value"
                                  >
                                  </el-option>
                                </el-option-group>
                              </el-select>
                              <div style="text-align: center" v-else-if="itm.type === 'boolean'">
                                <el-switch v-model="itm.value"></el-switch>
                                <label v-if="itm.value"> TRUE </label>
                                <label v-else> FALSE </label>
                              </div>
                              <el-input placeholder="Value" v-model="itm.value" v-else></el-input>
                            </el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="2">
                          <el-button
                            type="danger"
                            icon="el-icon-delete"
                            circle
                            size="small"
                            @click="deleteRule(index, idx)"
                          ></el-button>
                        </el-col>
                      </el-row>
                      <el-button type="text" @click="addRules(index)"> + add rule </el-button>
                    </div>
                  </div>
                  <div>
                    <el-button type="text" @click="addTransition"> + add transition </el-button>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>

          <div v-if="false">
            <div>
              <label class="journey-label">Before Action</label>
              <el-row v-for="(item, idx) in beforeAction" :key="'tran' + idx">
                <el-col :span="2">
                  <strong>#{{ idx + 1 }}</strong>
                </el-col>
                <el-col :span="11">
                  <el-input placeholder="Variable" v-model="item.variable"></el-input>
                </el-col>
                <el-col :span="11">
                  <el-input placeholder="Value" v-model="item.value"></el-input>
                </el-col>
              </el-row>
              <div>
                <el-button type="text" @click="addBeforeAction"> + add </el-button>
              </div>
              <br />
            </div>
            <div>
              <label class="journey-label">After Action</label>
              <el-row v-for="(item, idx) in afterAction" :key="'tran' + idx">
                <el-col :span="2">
                  <strong>#{{ idx + 1 }}</strong>
                </el-col>
                <el-col :span="11">
                  <el-input placeholder="Variable" v-model="item.variable"></el-input>
                </el-col>
                <el-col :span="11">
                  <el-input placeholder="Value" v-model="item.value"></el-input>
                </el-col>
              </el-row>
              <div>
                <el-button type="text" @click="addAfterAction"> + add </el-button>
              </div>
              <br />
            </div>
          </div>

          <div style="text-align: right; margin-top: 10px">
            <el-button @click="currentNode = null" size="small">Finish</el-button>
            <el-button @click="deleteNode" type="danger" size="small">Delete</el-button>
            <el-button @click="updateNode" type="primary" size="small">Save</el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import VueDiagramEditor from "vue-diagram-editor";
import "vue-diagram-editor/dist/vue-diagram-editor.css";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";

import { rest } from "@/store/api";
import { AUTOMATION_ATTRIBUTE_OPERATOR } from "@/store/modules/outreach/types";
import { getOutReachVariableExcludeField } from "@/helperMethods/outreach";

export default {
  components: {
    VueDiagramEditor,
  },
  props: {
    journey: {
      default() {
        return {
          id: 0,
          title: "",
          channel: "",
          settings: {},
          nodes: {},
          links: {},
        };
      },
    },
  },
  data() {
    return {
      activeTab: "actions",
      currentNode: null,
      currentNodeId: "",
      transitions: [],
      calculations: [],
      beforeAction: [],
      afterAction: [],
      formList: [],
      nodes: {},
      links: {},
      actionOpt: [
        {
          value: "fill_form",
          label: "Fill Form",
        },
        {
          value: "send_message",
          label: "Send Message",
        },
        {
          value: "calculations",
          label: "Calculations",
        },
      ],
      messageOpt: [
        {
          value: "whatsapp_template",
          label: "WhatsApp Template",
        },
        {
          value: "content_node",
          label: "Content Node",
        },
        {
          value: "text_message",
          label: "Text Message",
        },
      ],
      operationOpt: [
        {
          value: AUTOMATION_ATTRIBUTE_OPERATOR.EQUAL,
          label: "=",
        },
        {
          value: AUTOMATION_ATTRIBUTE_OPERATOR.NOT_EQUAL,
          label: "!=",
        },
        {
          value: AUTOMATION_ATTRIBUTE_OPERATOR.LESS_THAN,
          label: "<",
        },
        {
          value: AUTOMATION_ATTRIBUTE_OPERATOR.GREATER_THAN,
          label: ">",
        },
      ],
      priorityOpt: ["Low", "Normal", "High"],
      whenOpt: ["Immediately", "Later"],
      loading: false,
      forms: [],
      totalForms: 0,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < moment().hour(0).minute(0).second(0);
        },
      },
      appointmentApp: [
        {
          value: "calendly",
          label: "Calendly",
        },
        {
          value: "google",
          label: "Google Calendar",
        },
      ],
      vitalType: [
        {
          value: "blood_pressure",
          label: "Blood Pressure",
        },
        {
          value: "blood_sugar",
          label: "Blood Sugar",
        },
      ],
      valueType: [
        {
          value: "text",
          label: "Text",
        },
        {
          value: "boolean",
          label: "True/False",
        },
        {
          value: "variable",
          label: "Variable",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["contactAttributes"]),
    patientFields() {
      return getOutReachVariableExcludeField(undefined, this.contactAttributes);
    },
    contentNodes() {
      const data = this.$store.state.nodes.content || [];
      const items = _.map(data, (item) => {
        return item;
      });
      return items;
    },
    whatsappTemplates() {
      var templates = this.$store.state.whatsapp.templates || [];

      var items = _.filter(templates, function (item) {
        return item.status === "APPROVED";
      });

      return items;
    },
    transationVar() {
      const before = _.map(this.beforeAction, "variable");
      const after = _.map(this.afterAction, "variable");

      return _.union(before, after);
    },
  },
  watch: {
    "$refs.diagram.selectedNode"(val) {
      if (!val) {
        this.currentNode = null;
      }
      console.log("change");
      console.log(val);
    },
  },
  mounted() {
    this.fetchForms();
    this.fetchWhatsappTemplates();
    setTimeout(() => {
      this.init();
    }, 100);
  },
  methods: {
    vitalLabel(type) {
      const item = _.find(this.vitalType, {
        value: type,
      });
      if (item) {
        return item.label;
      } else {
        return "";
      }
    },
    init() {
      if (this.journey.nodes && Object.keys(this.journey.nodes).length > 0) {
        this.nodes = this.journey.nodes;
        this.links = this.journey.links || {};

        this.$refs.diagram.setModel({
          nodes: this.nodes,
          links: this.links,
        });
      } else {
        const start = {
          id: uuid(),
          title: "Start",
          size: {
            width: 75,
            height: 50,
          },
          coordinates: {
            x: 10,
            y: 100,
          },
          portsOut: {
            default: "",
          },
        };
        this.$refs.diagram.addNode(start);
        start.type = "Start";
        start.action = "default";
        this.nodes[start.id] = start;

        const end = {
          id: uuid(),
          title: "End",
          size: {
            width: 75,
            height: 50,
          },
          coordinates: {
            x: 400,
            y: 100,
          },
          portsIn: {
            default: "",
          },
        };
        this.$refs.diagram.addNode(end);
        this.$refs.diagram.addNode(end);
        end.type = "End";
        end.action = "default";
        this.nodes[end.id] = end;
      }

      setTimeout(() => {
        this.styleAllNode();
      }, 100);
    },
    async fetchForms(page = 1) {
      const response = await rest("get", "forms/v1");
      this.forms = response.data || [];
    },
    async fetchWhatsappTemplates() {
      if (this.journey.settings?.wa_endpoint) {
        await this.$store.dispatch("GET_WHATSAPP_MESSAGE_TEMPLATES", {
          data: {
            wa_endpoint: this.journey.settings.wa_endpoint,
          },
        });
      }
    },
    nodeColor(node) {
      const item = this.nodes[node.id];

      if (item.type === "Milestone") {
        return "#67C23A";
      } else if (item.type === "Notification") {
        return "#409EFF";
      } else if (item.type === "Alert") {
        return "#F56C6C";
      } else if (item.type === "Appointment Booking") {
        return "#67C23A";
      } else if (item.type === "Vital Monitoring") {
        return "#E6A23C";
      } else {
        return "#000";
      }
    },
    nodeDeletable() {
      return false;
    },
    addNode(type) {
      const node = {
        id: uuid(),
        title: type,
        size: {
          width: 150,
          height: 75,
        },
        portsIn: {
          default: "",
        },
        portsOut: {
          default0: "",
        },
      };
      this.$refs.diagram.addNode(node);
      node.type = type;

      if (type === "Milestone") {
        node.action = "fill_form";
      } else if (type === "Notification") {
        node.action = "send_message";
      } else if (type === "Alert") {
        node.alert_priority = "Normal";
      } else {
        node.action = "default";
      }

      this.nodes[node.id] = node;
      setTimeout(() => {
        this.styleNode(node);
      }, 100);
      this.onSave();
    },
    updateNode() {
      this.$refs.diagram.updateNode({
        id: this.currentNodeId,
        name: "title",
        value: this.currentNode.title,
      });

      let ports = {};
      if (this.transitions.length > 0 && this.currentNode.type !== "Start") {
        for (let index = 0; index < this.transitions.length; index++) {
          const element = this.transitions[index];
          ports["default" + index] = element.title;
        }

        const size = {
          with: 150,
          height: 50 + 25 * this.transitions.length,
        };
        this.$refs.diagram.updateNode({
          id: this.currentNodeId,
          name: "size",
          value: size,
        });
        this.currentNode.size = size;
      } else {
        ports = {
          default0: "",
        };
      }

      this.$refs.diagram.updateNode({
        id: this.currentNodeId,
        name: "portsOut",
        value: ports,
      });
      this.currentNode.portsOut = ports;

      this.nodes[this.currentNodeId] = this.currentNode;
      this.nodes[this.currentNodeId].transitions = this.transitions;
      this.nodes[this.currentNodeId].calculations = this.calculations;
      this.nodes[this.currentNodeId].beforeAction = this.beforeAction;
      this.nodes[this.currentNodeId].afterAction = this.afterAction;
      this.nodes[this.currentNodeId].forms = this.formList;
      this.nodes[this.currentNodeId].form = null;
      if (this.currentNode.form_id) {
        const form = _.find(this.forms, {
          id: this.currentNode.form_id,
        });
        if (form) {
          this.nodes[this.currentNodeId].form = form;
        }
      }
      this.onSave();
    },
    deleteNode() {
      this.$confirm("Delete this node?", "Confirmation", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.$refs.diagram.deleteNode(this.currentNodeId);
          this.currentNode = null;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addTransition() {
      this.transitions.push({
        title: "",
        condition_flag: true,
        rules: [],
      });
    },
    deleteTransition(index) {
      this.transitions.splice(index, 1);
    },
    addRules(index) {
      if (this.transitions.length === 0 && this.currentNode.type === "Start") {
        this.transitions.push({
          title: "",
          condition_flag: true,
          rules: [],
        });
      }
      this.transitions[index].rules.push({
        variable: "",
        operation: "",
        type: "",
        value: "",
      });
    },
    deleteRule(index, rule) {
      this.transitions[index].rules.splice(rule, 1);
    },
    addCalculations() {
      this.calculations.push({
        variable: "",
        type: "",
        value: "",
      });
    },
    deleteCalculations(index) {
      this.calculations.splice(index, 1);
    },
    addBeforeAction() {
      this.beforeAction.push({
        variable: "",
        value: "",
      });
    },
    addAfterAction() {
      this.afterAction.push({
        variable: "",
        value: "",
      });
    },
    addForm() {
      this.formList.push({
        id: "",
        link: "",
      });
    },
    deleteForm(index) {
      this.formList.splice(index, 1);
    },
    onSelectNode(id) {
      if (this.nodes[id].type === "End") {
        this.currentNode = null;
        this.currentNodeId = "";
      } else {
        this.currentNodeId = id;
        this.currentNode = _.cloneDeep(this.nodes[id]);
        this.transitions = [];
        if (this.currentNode.transitions) {
          for (let index = 0; index < this.currentNode.transitions.length; index++) {
            const element = this.currentNode.transitions[index];
            if (element.rules) {
              this.transitions.push = element;
            } else {
              this.transition.push({
                title: element.variable,
                condition_flag: true,
                rules: [
                  {
                    variable: element.variable,
                    operation: element.operation,
                    value: element.value,
                  },
                ],
              });
            }
          }
        }
        this.transitions = this.currentNode.transitions || [];
        this.calculations = this.currentNode.calculations || [];
        this.beforeAction = this.currentNode.beforeAction || [];
        this.afterAction = this.currentNode.afterAction || [];
        this.formList = this.currentNode.forms || [];
      }
    },
    onUpdateNode(node) {
      this.nodes[node.id].coordinates = node.coordinates;
      this.onSave();
    },
    onDeleteNode(id) {
      delete this.nodes[id];
      this.onSave();
    },
    onCreateLink(link) {
      this.links[link.id] = link;
      this.onSave();
    },
    onDeleteLink(id) {
      delete this.links[id];
      this.onSave();
    },
    styleNode(node) {
      const item = document.getElementById(node.id);
      if (item) {
        item.lastElementChild.style.display = "none";
        item.querySelector("svg").querySelector("rect").style.fill = this.nodeColor(node);
      }
    },
    styleAllNode() {
      document.querySelector(".diagram-editor__svg-content").style.width = "100%";
      for (var id in this.nodes) {
        const node = this.nodes[id];
        this.styleNode(node);
      }
    },
    onSave() {
      const flows = this.$refs.diagram.serialize();
      this.links = _.reduce(
        flows.links,
        function (result, value, key) {
          result[value.id] = value;
          return result;
        },
        {}
      );
      this.journey.nodes = this.nodes;
      this.journey.links = this.links;
      this.$emit("save", this.journey);
    },
  },
};
</script>

<style scoped>
.journey-label {
  font-weight: bold;
}
</style>
