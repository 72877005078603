import { OUT_REACH_MODULE } from "@/helperMethods/outreach";

const modules: { [key: string]: any } = {};
[
  "outreach",
  "templates",
  "alerts",
  "agents",
  "access_control",
  "analytics",
  "article",
  "evaluation",
  "broadcast",
  "core",
  "cention",
  "emotion",
  "export",
  "facebook",
  "fallback",
  "faq",
  "fasttext",
  "google_maps",
  "games",
  "infermedica",
  "floweditor",
  "webpage_editor",
  "twilio",
  "moobidesk",
  "rating",
  "handover",
  "line",
  "luis",
  "menu",
  "search",
  "services",
  "subscription",
  "teams",
  "telegram",
  "translate",
  "twitter",
  "voice",
  "webchat",
  "workplace",
  "makerChecker",
  "email",
  "organization",
  "whatsapp",
  "uccx",
  "people",
  "press",
  "press_release",
  "yoursingapore",
  "tnc",
  "zendesk",
  "pii",
  "bookworm",
  "googlemaps",
  "system",
  "visitors",
  "wechat",
  "alerts",
  "ameyo",
  "ciscoEce",
  "genesys",
  "prechatform",
  "postchatform",
  "prism",
  "viber",
  "pretrained_skills",
  "infobip",
  "imagePurge",
  "filePurge",
  "gateway",
  "form",
  "synthesia",
  "singpass",
  "developer",
  "zoom",
  "calendly",
  "azure",
  "openai",
  "ecare",
  "customer_care",
].forEach((moduleId) => {
  modules[moduleId] = {
    enabled: false,
  };
  if (moduleId === "access_control") {
    modules[moduleId].roles = modules["access_control"].roles = [
      {
        name: "admin",
        access: {
          [OUT_REACH_MODULE]: false,
          subscription: true,
          faq: true,
          editor: true,
          editor_approve: true,
          webpage_checker: true,
          analytics: true,
          webchat: true,
          livechat: true,
          games: true,
          users: true,
          broadcast: true,
          labs: true,
          audit: true,
          documentation: true,
          access_control: true,
          export: true,
          agents: true,
          whatsapp_checker: true,
          gateway: true,
          form: true,
          zoom: true,
          ecare: true,
          customer_care: true,
        },
      },
      {
        name: "editor",
        access: {
          [OUT_REACH_MODULE]: true,
          subscription: false,
          faq: true,
          editor: true,
          editor_approve: false,
          webpage_checker: false,
          analytics: false,
          webchat: false,
          livechat: false,
          games: false,
          users: false,
          broadcast: false,
          labs: false,
          audit: false,
          documentation: false,
          access_control: false,
          agents: false,
          whatsapp_checker: false,
          gateway: true,
          form: true,
          zoom: false,
          ecare: false,
          customer_care: false,
        },
      },
      {
        name: "agent",
        access: {
          [OUT_REACH_MODULE]: false,
          subscription: false,
          faq: false,
          editor: false,
          editor_approve: false,
          webpage_checker: false,
          analytics: false,
          webchat: false,
          livechat: true,
          games: false,
          users: false,
          broadcast: false,
          labs: false,
          audit: false,
          documentation: false,
          access_control: false,
          agents: false,
          whatsapp_checker: false,
          gateway: false,
          form: false,
          zoom: false,
          ecare: false,
          customer_care: false,
        },
      },
    ];
  }
});

export default modules;
